$(function() {
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf('android') > -1 && ua.indexOf('mobile');

  if(isAndroid && oneMonthPassed()) {
    $('.nav__app-banner').show();
  }

  $('.nav__app-banner__right > a, .nav__app-banner___left_close').click(function(_e) {
    var now = (new Date).getTime();
    window.localStorage.setItem('appBannerClicked', now);
    $('.nav__app-banner').hide();
  });
});

function oneMonthPassed() {
  var oneMonth = 2592000000;
  var lastClicked = Number(window.localStorage.getItem('appBannerClicked'));

  return ((new Date).getTime() - lastClicked) > oneMonth;
}

