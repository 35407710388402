// ServerTime
import '../javascripts/server_time';

import '../javascripts/polyfil/string_includes_polyfil';
import $ from 'jquery';
window.jQuery = window.$ = $;
import '@rails/activestorage';
import 'foundation-sites';
import '../javascripts/android_app_banner';
import 'lodash';
import '../javascripts/ga/google_analytics4';

// Initialize Rails UJS
import Rails from '@rails/ujs';
Rails.start();
